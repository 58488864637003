import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import styled from 'styled-components';
import { Paper, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CopyToClipboardButton from '~controls/copyToClipboardButton.component'; // eslint-disable-line
import GetAppIcon from '@material-ui/icons/GetApp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PrintIcon from '@material-ui/icons/Print';

import dragIcon from './drag.svg';
import { asItemImageUrl } from '../common/item.api.js';
import Circle from './circle.component';
import Item from '~search/item.component';
import { getBaseUrl } from '~infra/api.util';

const propTypes = {
  item: PropTypes.object,
  onUnselect: PropTypes.func,
  onSelectPrev: PropTypes.func,
  onSelectNext: PropTypes.func,
};

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(1) * 1.5,
    marginRight: theme.spacing(1) * 1.5,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    height: 16,
    width: 16,
  },
});

const KEY_NAMES = {
  BASE: 'Tietokanta',
  SIS: 'Sisältö',
  PKA: 'Paikka',
  KJA: 'Kuvaaja',
  KPV: 'Kuvauspvm',
  KA: 'Kuvausaika (klo)',
  JULK: 'Julkaistu',
  LOHJ: 'Lehden numero',
  TTUN: 'Sivu',
  TPV: 'Arkistointipvm',
  NTUN: 'Arkistointiaika (klo)',
  KO: 'Käyttöoikeus',
  OIK: 'Tekijänoikeus',
  HUOM: 'Huom',
  ASAN: 'Asiasanat',
  JKT: 'Kuvateksti',
  ORIG: 'Alkup. tiedostonimi',
  HENK: 'Henkilö',
  EXT: 'Tiedostomuoto',
  LPV: 'Tiedostokoko',
  RES: 'Megapikselikoko',
  LJA: 'Sanoma BU',
  LUOK: 'Pääjulkaisu',
  OSAI: 'Alkup. kuva-id',
  NIMI: 'Tunniste',
};

const KEY_ORDER = [
  'BASE',
  'SIS',
  'PKA',
  'KJA',
  'KPV',
  'KA',
  'JULK',
  'LOHJ',
  'TTUN',
  'TPV',
  'NTUN',
  'KO',
  'OIK',
  'HUOM',
  'ASAN',
  'JKT',
  'ORIG',
  'HENK',
  'EXT',
  'LPV',
  'RES',
  'LJA',
  'LUOK',
  'OSAI',
  'NIMI',
];

function compare(a, b) {
  const indexA = KEY_ORDER.indexOf(a);
  const indexB = KEY_ORDER.indexOf(b);
  if (indexA < indexB) {
    return -1;
  } else if (indexA > indexB) {
    return 1;
  }
  return 0;
}

const asImageLink = (item) => {
  const index = window.location.href.indexOf('/pictures');
  const base = `${window.location.href.substring(0, index)}/picture`;
  const fields = item.fields
    ? `&BASE=${item.fields.BASE}&TYPE=${item.fields.TYPE}&FILE=${item.fields.FILE}&EXT=${item.fields.EXT}`
    : '';
  return `${base}?database=${item.name}%2FKUVA${fields}#${item.id}`;
};

/**
 * https://jira.sanoma.com/browse/CFR-245
 */
const asDragAndDropLink = (item) => {
  const params = queryString.stringify({
    dbType: item.type,
    dbase: item.fields.BASE[0],
    uniqueId: item.id,
    fileType: 1,
    conversioId: 'systeminternaldownload',
  });

  // Use hsarkisto3 address
  let host = '';
  if (window.location.href.indexOf('taitodev.com') !== -1) {
    const baseUrl = getBaseUrl('');
    const hostMatch = baseUrl.match(/(https?:\/\/[^/]*)/);
    host = hostMatch?.[1]?.includes('hsarkisto1')
      ? hostMatch[1].replace('hsarkisto1', 'hsarkisto3')
      : '';
  } else {
    host = window.location.origin.includes('hsarkisto1')
      ? window.location.origin.replace('hsarkisto1', 'hsarkisto3')
      : '';
  }

  // Example drag and drop link from Harry. Unfortunately can't test this in
  // dev environment.
  // eslint-disable-next-line
  // https://hsarkisto1.materialbank.net:8443/MediaKsiPublicWEB/internal/down.load?dbase=KUVA&dbType=kuva&uniqueId=100znsie&fileType=1&conversioId=systeminternaldownload
  return `${host}/MediaKsiPublicWEB/internal/down.load?${params}`;
};

const SelectedPicture = ({
  item,
  classes,
  onUnselect,
  onSelectPrev,
  onSelectNext,
}) => {
  const [itemId, setItemId] = useState(null);
  const selectedPictureEl = useRef();

  // OLD: As a default, we assume that images are landscape oriented
  // NEW: While refactoring, noticed that these states were declared, but not used anywhere
  // const [imgWidth, setImgWidth] = useState(3);
  // const [imgHeight, setImgHeigth] = useState(2);

  useEffect(() => {
    if (itemId !== item.id && selectedPictureEl) {
      setItemId(item.id);
      const domEl = selectedPictureEl.current;
      if (domEl) {
        const middle = domEl.offsetParent.clientHeight / 2;
        const half = domEl.clientHeight / 2;
        const position = middle - half;
        domEl.offsetParent.scrollTop = Math.max(0, domEl.offsetTop - position);
      }
    }
  }, [item]);

  return (
    <SelectedPictureWrapper ref={selectedPictureEl}>
      <Item
        selected
        showNav
        onUnselect={onUnselect}
        onSelectPrev={onSelectPrev}
        onSelectNext={onSelectNext}
        minHeight="20vh"
        padding="20px"
      >
        <Content>
          <ContentLeft>
            <PreviewWrapper>
              <Preview
                src={asItemImageUrl(item, 'Preview')}
                alt="KUVA"
                onClick={() => window.open(asImageLink(item))}
              />
            </PreviewWrapper>
            <Actions>
              <CopyToClipboardButton
                buttonText="Kopioi linkki"
                buttonClass={classes.button}
                infoMessage="Linkki kopioitu leikepöydälle"
                copyContent={asImageLink(item)}
              />

              {/* TODO API does not support download=false yet? */}
              <Button
                variant={'contained'}
                color="primary"
                className={classes.button}
                onClick={() => window.open(asImageLink(item))}
              >
                Avaa
                <OpenInNewIcon className={classes.rightIcon} />
              </Button>

              <Button
                variant={'contained'}
                color="primary"
                className={classes.button}
                onClick={() =>
                  (window.location = asItemImageUrl(item, 'Original', true))
                }
              >
                Lataa
                <GetAppIcon className={classes.rightIcon} />
              </Button>
              <Button
                variant={'contained'}
                color="primary"
                className={classes.button}
                onClick={() => window.print()}
              >
                Tulosta
                <PrintIcon className={classes.rightIcon} />
              </Button>
              <DragAndDropLink
                // We want draggable URL, not clickable link, See CFR-245
                title="Vie kuva Navigaan"
                onClick={(e) => e.preventDefault()}
                target="_blank"
                href={asDragAndDropLink(item)}
              >
                <DragAndDropButton
                  disableRipple
                  variant={'contained'}
                  color="primary"
                  className={classes.button}
                >
                  Raahattava suoralinkki
                  <DragAndDropIcon src={dragIcon} alt="Raahattava linkki" />
                </DragAndDropButton>
              </DragAndDropLink>
            </Actions>
          </ContentLeft>
          <ContentRight>
            <ResultInfo>
              <span>
                {item.fields.KTUN ? item.fields.KTUN : 'Ei kuvatunnusta'}
              </span>
              {/* Show "copyright/published" circles only if field exists and
                  is not empty */}
              {!!item.fields.JULK && item.fields.JULK[0].length !== 0 && (
                <Circle color="#ffed0f" text="Dokumentti on julkaistu." />
              )}
              {!!item.fields.KO && item.fields.KO[0].length !== 0 && (
                <Circle
                  color="#ff421f"
                  text="Käyttöoikeus-kentässä on tekstiä, tarkista myös Tekijänoikeus ja Huom -kentät." // eslint-disable-line
                />
              )}
            </ResultInfo>
            <Table>
              <tbody>
                {Object.keys(item.fields)
                  .filter((key) => !!KEY_NAMES[key])
                  .filter((key) => item.fields[key][0].length !== 0)
                  .sort(compare)
                  .map((key) => (
                    <tr key={key}>
                      <th>
                        {`${KEY_NAMES[key]}: `}
                        <ToolTip>{`${key}`}</ToolTip>
                      </th>
                      <td>{item.fields[key]}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </ContentRight>
        </Content>
      </Item>
    </SelectedPictureWrapper>
  );
};

const SelectedPictureWrapper = styled(Paper)`
  display: block;
  width: 100%;
  min-height: 20vh;
  margin: 4px 0 16px 0;
  background-color: #29323b !important;
  color: #ffffff !important;
  font-size: 12px;
  line-height: 1.4;
  position: relative;
  z-index: 1;
  border-radius: 4px;

  @media (max-width: 480px) {
    width: 100%;
  }

  @media print {
    background-color: #ffffff !important;
    color: #000000 !important;
    height: auto !important;
    overflow: visible !important;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;

  @media screen and (min-width: 769px) {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    align-content: center !important;
  }

  @media screen and (min-width: 1700px) {
    width: 80%;
    margin: 0 auto;
  }

  @media screen and (min-width: 2000px) {
    width: 65%;
  }

  @media screen and (min-width: 3000px) {
    width: 50%;
  }

  @media print {
    width: 100% !important;
    height: auto !important;
    overflow: visible !important;
    display: inline !important;
  }
`;

const ContentLeft = styled.div`
  width: 50%;
  height: 100%;

  @media screen and (max-width: 768px) {
    width: 100% !important;
    height: auto;
    position: relative;
    padding-bottom: 20px;
  }

  @media print {
    width: 100% !important;
    height: auto !important;
    overflow: visible !important;
    display: inline !important;
  }
`;

const PreviewWrapper = styled.div`
  width: 100%;
  background-color: #212930;
  padding: 0;
`;

const Preview = styled.img`
  max-width: 100%;
  max-height: 35vh;
  display: block;
  margin: 0 auto;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    max-height: 30vh;
  }
`;

const Actions = styled.div``;

const ContentRight = styled.div`
  width: 50%;
  height: 100%;
  padding-left: 2%;

  @media screen and (max-width: 768px) {
    width: 100% !important;
    height: auto;
    position: relative;
    padding-left: 0;
  }

  @media print {
    width: 100% !important;
    height: auto !important;
    overflow: visible !important;
    display: inline !important;
  }
`;

const ResultInfo = styled.span`
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  span {
    margin-right: 6px;
  }

  @media print {
    font-weight: 400;
  }
`;

const Table = styled.table`
  margin-top: 10px;
  text-align: left;
  width: 100%;
  border: 0;

  tr {
    width: 100%;
    padding-left: 0;
  }

  th {
    vertical-align: top;
    padding-right: 5px;
    padding-left: 0;
    padding-bottom: 3px;
    width: 120px;

    &:hover > span {
      visibility: visible;
    }

    @media print {
      font-weight: 400;
    }
  }

  td {
    vertical-align: top;
    padding-left: 0;
    padding-bottom: 2px;
  }
`;

const ToolTip = styled.span`
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 8px;
  position: absolute;
  margin-left: 8px;
  margin-top: -5px;
  z-index: 1;

  &:after {
    content: ' ';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
`;

const DragAndDropLink = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

const DragAndDropIcon = styled.img`
  margin-left: 10px;
  width: 16px;
`;

const DragAndDropButton = styled(Button)`
  && {
    cursor: grab;
  }
`;

SelectedPicture.propTypes = propTypes;

export default withStyles(styles)(SelectedPicture);
