import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';

import { magazinesBySection } from './search.constants';
import photographermap from './photographermap.json';
import {
  getBaseUrl,
  post,
  parseValue,
  getSivuarkistoUrl,
} from '~infra/api.util';

// ------------------------------------------------------------------
// Data conversion
// ------------------------------------------------------------------

// Map for converting field names of old data
const fieldNameConversionMap = {
  // EXAMPLE:
  // oldName: newName,
};

const fieldName = (field) => fieldNameConversionMap[field.name] || field.name;
const fieldValue = (field) => parseValue(field.value);
const fieldHighlight = (field) => {
  return field.highlightValue && parseValue(field.highlightValue);
};

const groupFields = (origFields) => {
  const fields = {
    texts: [],
    highlights: [],
  };
  origFields.forEach((field) => {
    // NOTE: multiple fields with same name, so we need to use arrays here.
    const textFieldTypes = ['VO', 'IN', 'TX', 'BY', 'KT'];
    const name = fieldName(field);
    let value = fieldValue(field);
    const highlight = fieldHighlight(field);

    // OTS might include Gredi's internal (read: weird) tags (issues #52, #54)
    if (name === 'OTS' || textFieldTypes.includes(name)) {
      value = value.replace(/<[^>]+>/g, '');
    }
    // We need to keep an ordered list of text items (issue #51)
    if (textFieldTypes.includes(name)) {
      fields.texts.push({ name, value, highlight });
    }
    if (fields[name]) {
      fields[name].push(value);
    } else {
      fields[name] = [value];
    }
  });
  return fields;
};

const convertDoc = (db, origDoc) => {
  return {
    id: origDoc.uniqueId,
    name: origDoc.baseName,
    type: origDoc.baseType,
    db: db.name,
    dbType: db.type,
    fields: groupFields(origDoc.fields),
  };
};

const noCriteriaResult = {
  items: [],
  totalCount: -1,
};

const notFoundResult = {
  items: [],
  totalCount: 0,
};

// ------------------------------------------------------------------
// Services
// ------------------------------------------------------------------

const getDatabase = (dbCriteria) => {
  const dbInfo = dbCriteria.split('-');
  return {
    name: dbInfo[0],
    type: dbInfo[1],
  };
};

export const fetchOptions = async ({
  section,
  changedCriteriaName,
  changedCriteriaValue,
}) => {
  let criteriaOptions = null;
  if (section === 'pictures' && changedCriteriaName === 'database') {
    // Fetch photographers of a database
    const database = getDatabase(changedCriteriaValue);
    const req = {
      request: {
        task: 'GetSelectResource',
        basename: database.name,
        basetype: database.type,
        tag: 'KJA',
        language: 'fi',
      },
    };
    const response = await post('AjaxResourceService', req);
    const photographers = response.data.response.list
      .map((entry) => {
        return {
          value: parseValue(entry.value),
          title: parseValue(entry.label),
        };
      })
      .sort((a, b) => {
        return a.title.localeCompare(b.title);
      });
    criteriaOptions = {
      photographers: _.uniqBy(photographers.slice(1), 'value'),
    };
  }
  return criteriaOptions;
};

const addField = (fields, name, valueArray, negate = false) => {
  const rule =
    valueArray &&
    valueArray.length &&
    valueArray.map((e) => `${negate ? 'NOT ' : ''}'${e}':e`).join(' OR ');
  if (rule) {
    fields.push({
      name,
      value: rule,
    });
  }
};

const addDate = (dates, name, startDate, endDate, use) => {
  let rule = '';
  const start = startDate ? moment(startDate).format('YYYYMMDD') : null;
  const end = endDate ? moment(endDate).format('YYYYMMDD') : null;
  if (use && (start || end)) {
    // Special case for handling invalid date fields that contain also time as
    // string: ignore the time at least when searching for a specific date
    // by using = operator instead of >= <=.
    const gt = start === end ? '=' : '>=';
    const lt = start === end ? '=' : '<=';
    if (start) {
      rule += `%${name}${gt}${start}`;
    }
    if (start && end) {
      rule += ' AND ';
    }
    if (end) {
      rule += `%${name}${lt}${end}`;
    }
  }
  if (rule) {
    dates.push(`(${rule})`);
  }
};

const getDateCriteria = (section, criteria) => {
  const dates = [];
  if (section === 'texts') {
    addDate(dates, 'PVM', criteria.startDate, criteria.endDate, true);
  } else {
    addDate(
      dates,
      'TPV',
      criteria.startDate,
      criteria.endDate,
      criteria.dateType === 'archiveDate' || criteria.dateType === 'all'
    );
    addDate(
      dates,
      'KPV',
      criteria.startDate,
      criteria.endDate,
      criteria.dateType === 'shootingDate' || criteria.dateType === 'all'
    );
    // TODO pictures section uses both JULK and JJA for publish?
    addDate(
      dates,
      'JPV',
      criteria.startDate,
      criteria.endDate,
      criteria.dateType === 'publishDate' || criteria.dateType === 'all'
    );
  }
  return dates;
};

const photographerParams = (photographers) => {
  if (photographers) {
    let params = [];
    photographers.forEach((p) => {
      const values = photographermap[p] || [p];
      params = params.concat(values);
    });
    return params;
  }
  return photographers;
};

export const fetchItems = async ({ section, criteria, paging }) => {
  try {
    const freeClauses = [];
    const refineClauses = [];
    const fields = [];

    // Return an empty result if database has not been given
    if (!criteria.database) {
      return noCriteriaResult;
    }

    // Criteria: database
    const database = getDatabase(criteria.database);

    // Criteria: free search text
    if (criteria.simpleText) {
      freeClauses.push(`(${criteria.simpleText})`);
    }

    // Criteria: dates
    if (criteria.startDate) {
      fields.push({ Date: moment(criteria.startDate).format('YYYYMMDD') });
    }
    if (criteria.endDate) {
      fields.push({ toDate: moment(criteria.endDate).format('YYYYMMDD') });
    }

    const magazines = (criteria.magazines || [])
      .map((magazine) =>
        Object.values(magazinesBySection[section])
          .find((items) => items[magazine])
          [magazine].map((i) => i.search)
      )
      .reduce((a, b) => a.concat(b), []);

    // Criteria: selectable fields
    // TODO pictures section uses both JULK and JJA for publish?
    addField(fields, section === 'pictures' ? 'JULK' : 'LEH', magazines);

    addField(fields, 'KJA', photographerParams(criteria.photographers));

    // Refine: Do not retrieve articles of type 'lista' (issue #62)
    if (section === 'texts') {
      refineClauses.push('not lista:e.ty.');
    }

    // To filter out removed resources with an empty query,
    // search resources with a document number
    if (freeClauses.length === 0 && fields.length === 0) {
      freeClauses.push('(%DOCN)');
    }

    const req = {
      request: {
        task: 'SearchDocuments',
        database,
        searchcriteria: {
          free: freeClauses.join(' AND '),
          fields,
          refineclause: refineClauses.join(' AND '),
          operator: 'AND',
          highlight: 1,
          orderby: criteria.sortBy !== '-' ? criteria.sortBy : undefined,
          pagenumber: paging.page + 1,
          pagesize: paging.pageSize,
        },
      },
    };

    const response = await post('AjaxSearchService', req);

    const totalCount = parseInt(response.data.response.documentCount, 10);
    return {
      items: response.data.response.documents
        // Slice is for mitigating an API problem
        // (in some cases returns more than one page)
        .slice(
          0,
          Math.min(paging.pageSize, response.data.response.documents.length)
        )
        .map((doc) => convertDoc(database, doc)),
      totalCount,
      isSorted: totalCount <= 65000,
    };
  } catch (err) {
    if (err.statusCode === 303) {
      // STATUS: Not found
      return notFoundResult;
    }
    throw err;
  }
};

export const readItem = async ({ criteria, itemId }) => {
  const database = getDatabase(criteria.database);
  const req = {
    request: {
      task: 'SearchDocuments',
      database,
      searchcriteria: {
        uniqueid: itemId,
      },
    },
  };

  const response = await post('AjaxSearchService', req);
  const documents = response.data.response.documents;
  return documents && documents.length
    ? convertDoc(database, documents[0])
    : null;
};

export const asItemImageUrl = (item, imageType, download) => {
  const baseUrl = getBaseUrl('down.lload');
  const dlType = download ? 'disk' : 'app';
  let fileType = 1;
  let dataType = '';
  if (imageType === 'Preview') {
    fileType = 2;
    dataType = 'Preview';
  }
  const conversionFields =
    imageType === 'Original' ? '&imgConversioConfName=1' : '';
  const hsFields = item.fields
    ? `&HSdataBase=${item.fields.BASE}&HSdbType=${item.fields.TYPE}&HSfileBody=${item.fields.FILE}&HSfileExt=${item.fields.EXT}`
    : '';
  return `${baseUrl}?DbType=${item.dbType}&Database=${item.db}&uniqueId=${item.id}&FileType=${fileType}&DataType=${dataType}&targetDownloadType=${dlType}${conversionFields}${hsFields}`; // eslint-disable-line
};

// WIP currently used in TextPage, doesn't like dbType CONCATENATED
// URLs go to API error page if API can't provide content
const fileTypeMap = {
  clip: 5,
  page: 6,
  clipThumb: 7,
  pageThumb: 8,
};

export const asItemDownloadUrl = (item, downloadType, download) => {
  const baseUrl = getBaseUrl('down.lload');
  const dlType = download ? 'disk' : 'app';
  const fileType = fileTypeMap[downloadType] || '';
  return `${baseUrl}?DbType=TEKSTI&Database=${item.name}&fileBody=${item.fields.LEI}&fileType=${fileType}&targetDownloadType=${dlType}`;
};

/** Finds the full text product title from a search value */
const findProductTitle = (search, section = 'texts') => {
  for (const majorMagazine of Object.values(magazinesBySection[section])) {
    for (const productTitle of Object.keys(majorMagazine)) {
      if (
        majorMagazine[productTitle].some((entry) => entry.search === search)
      ) {
        return productTitle;
      }
    }
  }
  return undefined;
};

/** Constructs a link to search the product pages on sivuarkisto  */
export const asSivuarkistoUrl = (item, section) => {
  const sivuarkistoUrl = getSivuarkistoUrl();
  // These will be used as fallback values if LEH or PVM are missing
  const [, search, y, m, d] =
    item.id.match(/([^\d]*)(\d{4})(\d{2})(\d{2})/) || [];

  const magazineTitle = item.fields.LEH?.join('');
  const productTitle =
    (magazineTitle && findProductTitle(magazineTitle, section)) ||
    (search && findProductTitle(search, section));
  if (!productTitle) return sivuarkistoUrl;

  // Double encode slashes, since Apache isn't configured properly in prod
  // Please, don't ask me why it can't be configured
  const productFilter = encodeURIComponent(
    productTitle.replace('/', '%2F').replace('\\', '%5C')
  );
  const [, year, month, day] = item.fields.PVM?.join('')?.match(
    /(\d{4})(\d{2})(\d{2})/
  ) || [undefined, y, m, d];
  const dateFilter = year
    ? `/${year}?${queryString.stringify({ month, day })}`
    : '?';
  const articleFilter = `&article=${item.id}`;

  return `${sivuarkistoUrl}/${productFilter}${dateFilter}${articleFilter}`;
};
