import { call, put, takeLatest, fork, select } from 'redux-saga/effects';
import queryString from 'query-string';

import * as userApi from './user.api';
import { history, getBaseHref } from '~infra/browser.util';

// TODO move to common?
export const sectionsByPath = {
  '/pictures': 'pictures',
  '/texts': 'texts',
  '/texts/browse': 'texts',
};

const shouldRedirectHome = (pathname) => {
  if (!pathname) {
    return true;
  } else if (pathname === '/') {
    return true;
  } else if (pathname === '/login') {
    return true;
  }

  return false;
};

// Login and logout

function* login(action) {
  try {
    localStorage.removeItem('sessionId');
    const sessionId = yield call(userApi.login, {
      username: action.payload.username,
      password: action.payload.password,
    });
    localStorage.setItem('sessionId', sessionId);
    yield put({ type: 'USER/AFTER_LOGIN', payload: {} });
  } catch (err) {
    const error = { credentials: false, somethingElse: false };
    if (err.statusCode === 403) {
      error.credentials = true;
    } else {
      error.somethingElse = true;
    }
    yield put({ type: 'USER/LOGIN_ERROR', payload: error });
  }
}

function* watchLogin() {
  yield takeLatest('USER/LOGIN', login);
}

function* logout() {
  try {
    localStorage.removeItem('sessionId');
    const extLogoutUrl = document
      .getElementById('app')
      .getAttribute('data-ext-logout');
    if (extLogoutUrl) {
      window.location = getBaseHref() + extLogoutUrl;
    } else {
      yield put({ type: 'COMMON/INIT_APP', payload: {} });
      history.push({
        pathname: '/',
      });
    }
  } catch (err) {
    console.error(err);
  }
}

function* watchLogout() {
  yield takeLatest('USER/LOGOUT', logout);
}

// Select first database automatically

function* selectDefaultDatabase(action) {
  try {
    const section = action.payload.section;

    const currentState = yield select((state) => {
      return {
        database: state.search[section].criteria.database,
        databases: state.common.databasesBySection[section],
      };
    });

    if (!currentState.database || action.type === 'SEARCH/CLEAR') {
      yield put({
        type: 'SEARCH/UPDATE_CRITERIA',
        payload: {
          section,
          name: 'database',
          value: currentState.databases[0] && currentState.databases[0].id,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
}

function* watchSelectDefaultDatabase() {
  yield takeLatest('SEARCH/CLEAR', selectDefaultDatabase);
}

// Init user

function* initUser() {
  console.log('inituser start');
  try {
    console.log('readUser call start');
    const user = yield call(userApi.readUser);
    console.log('readUser call success ', user);
    let databases = [];

    try {
      console.log('readuserdatabases start');
      databases = yield call(userApi.readUserDatabases);
    } catch (err) {
      console.log('unexpected readuserdatabases error', err);

      // OK. User does not have rights.
      // TODO check error code
    }

    const databasesBySection = {
      initialized: true,
      pictures: databases.filter((db) => db.category === 'PIC'),
      texts: databases.filter((db) => db.category === 'TEXT'),
    };

    yield put({
      type: 'COMMON/INIT_USER',
      payload: {
        user,
        databasesBySection,
      },
    });

    // Select texts section unless section has already been set in url

    const st = yield select((state) => {
      return {
        pathname: state.router.location.pathname,
        search: state.router.location.search,
        currentSection: sectionsByPath[state.router.location.pathname],
        databaseSelected:
          state.router.location.search.indexOf('database=') !== -1,
      };
    });

    const { redirect } = queryString.parse(st.search);

    if (redirect) {
      history.replace(redirect);
    } else if (shouldRedirectHome(st.pathname)) {
      history.replace({ pathname: '/texts' });
    }

    // Select first database by default unless database has already
    // been set in url

    if (st.currentSection !== 'pictures' || !st.databaseSelected) {
      yield call(selectDefaultDatabase, {
        payload: { section: 'pictures' },
      });
    }

    if (st.currentSection !== 'texts' || !st.databaseSelected) {
      yield call(selectDefaultDatabase, {
        payload: { section: 'texts' },
      });
    }

    // Cause last fetchItems call that won't be cancelled
    // TODO remove this hack
    if (st.currentSection && st.databaseSelected) {
      yield put({
        type: 'SEARCH/RELOAD',
        payload: {
          section: st.currentSection,
        },
      });
    }
  } catch (err) {
    console.log('expected fail', err);

    localStorage.removeItem('sessionId');
    const extLoginUrl = document
      .getElementById('app')
      .getAttribute('data-ext-login');

    console.log('found extloginurl', extLoginUrl);
    if (extLoginUrl) {
      console.log('setting faterloginurl...', window.location.href);
      localStorage.setItem('afterLoginUrl', window.location.href);
      console.log(`redirecting to ${getBaseHref()}${extLoginUrl}`);
      window.location = getBaseHref() + extLoginUrl;
    } else {
      const { pathname, search } = window.location;

      if (shouldRedirectHome(pathname)) {
        history.replace({ pathname: '/login' });
      } else {
        const redirect = queryString.stringify({ redirect: pathname + search });
        history.replace({ pathname: '/login', search: redirect });
      }
    }
  }
}

function* watchInitUser() {
  yield takeLatest('COMMON/INIT_APP', initUser);
  yield takeLatest('USER/AFTER_LOGIN', initUser);
}

// Init

export default function* userSagas() {
  yield fork(watchLogin);
  yield fork(watchLogout);
  yield fork(watchInitUser);
  yield fork(watchSelectDefaultDatabase);
}
