import magazines from './holviProductTitles.json';
import sortOptions from './sortoptions.json';

export const magazinesBySection = {
  texts: magazines,
  pictures: magazines,
};

export const sortOptionsBySection = sortOptions;

export const helpContentBySection = {
  pictures:
    'https://sanoma.sharepoint.com/sites/smf/Sanomapedia/Pages/Arkiston%20uusi%20k%C3%A4ytt%C3%B6liittym%C3%A4.aspx', // eslint-disable-line
  texts:
    'https://sanoma.sharepoint.com/sites/smf/Sanomapedia/Pages/Arkiston%20uusi%20k%C3%A4ytt%C3%B6liittym%C3%A4.aspx', // eslint-disable-line
};
