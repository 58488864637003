import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { getBaseHref } from '~infra/browser.util';

export const getNumOfPages = (paging, results) => {
  const calculated = Math.max(
    1,
    Math.ceil(results.totalCount / paging.pageSize)
  );
  // Paging is always between 1-999
  return calculated;
};

export const prevFunc = (index, paging, onUpdatePaging, onSelectItem) => {
  if (index > 0) {
    // There is a previous item on current page
    return (selectedBy) => onSelectItem(index - 1, selectedBy);
  } else if (paging.page > 0) {
    // There is a previous page
    return () =>
      onUpdatePaging(
        'page',
        paging.page - 1, // Load the previous page first...
        paging.pageSize - 1 // ...and then select the last item
      );
  }
  return null;
};

export const nextFunc = (
  index,
  results,
  paging,
  onUpdatePaging,
  onSelectItem
) => {
  if (index < results.items.length - 1) {
    // There is a next item on current page
    return (selectedBy) => onSelectItem(index + 1, selectedBy);
  } else if (paging.page < getNumOfPages(paging, results) - 1) {
    // There is a next page
    return () =>
      onUpdatePaging(
        'page',
        paging.page + 1, // Load the next page first...
        0 // ...and then select the first item on page
      );
  }
  return null;
};

const excludeParams = {
  pageSize: true,
};

export const addSearchStateToPath = (
  path,
  criteria,
  paging,
  itemId, // Optional
  keepBase = false
) => {
  // Get data from store to be used as url params
  let params = {
    ...criteria,
    ...paging,
  };

  // Format parameters to a user friendly format
  // TODO avoid lodash and moment in common implementation?
  params = _.mapValues(params, (value, key) => {
    return key.endsWith('Date') && value
      ? moment(value).format('YYYYMMDD')
      : value;
  });
  params.page += 1;

  // Filter empty and unwanted values from params
  params = _.pickBy(params, (value, key) => {
    return !excludeParams[key] && value;
  });

  const base = keepBase ? getBaseHref() : '';
  const query = queryString.stringify(params);
  const hash = itemId ? `#${itemId}` : '';
  return `${base}${path}?${query}${hash}`;
};
