import { post, parseValue } from '~infra/api.util';

export const login = async ({ username, password }) => {
  const req = {
    request: {
      task: 'Login',
      credentials: {
        login: username,
        password,
      },
    },
  };
  const resp = await post('AjaxLogin', req);
  // NOTE: wrong password/username gives back html as response(???)
  //       and we will never reach this part - instead an error is thrown,
  //       which gets caught in user.sagas
  return resp.data.response.path.split('=')[1];
};

export const logout = () => {
  const req = {
    request: {
      task: 'Logout',
    },
  };
  return post('AjaxLogin', req);
};

export const readUser = async () => {
  const req = {
    request: {
      task: 'GetUserAccount',
    },
  };
  const resp = await post('AjaxAccountService', req);

  return {
    username: parseValue(resp.data.response.userDTO.login),
    name: parseValue(resp.data.response.userDTO.name),
    firstName: parseValue(resp.data.response.userDTO.firstName),
    lastName: parseValue(resp.data.response.userDTO.lastName),
  };
};

const addDatabase = (databases, db, level, keyPrefix) => {
  databases.push({
    id: `${db.baseName}-${db.baseType}`,
    key: `${keyPrefix}-${db.baseName}-${db.baseType}`,
    name: db.baseName,
    type: db.baseType,
    category: db.category.name,
    title: parseValue(db.description),
    level,
  });
  // NOTE: Request from Gredi/Sanoma: Do not show children of G2TX database
  if (db.childs && db.baseName !== 'G2TX') {
    db.childs.forEach((childDb) => {
      addDatabase(databases, childDb, level + 1, db.baseName);
    });
  }
};

export const readUserDatabases = async () => {
  const req = {
    request: {
      task: 'GetUserDatabases',
      levelLimit: 1,
    },
  };
  const resp = await post('AjaxDatabaseService', req);

  const databases = [];
  resp.data.response.databases.forEach((db) => {
    addDatabase(databases, db, 0, '');
  });
  return databases;
};
