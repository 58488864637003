import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { prevFunc, nextFunc } from './search.util';

const propTypes = {
  criteria: PropTypes.any.isRequired,
  results: PropTypes.shape({
    items: PropTypes.array,
    selectedIndex: PropTypes.number.isRequired,
  }).isRequired,
  filters: PropTypes.any,
  paging: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
  }).isRequired,
  onUpdatePaging: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  onShowItem: PropTypes.func.isRequired,
  itemComponent: PropTypes.any.isRequired,
  openedItemComponent: PropTypes.any,
};

const Results = ({
  criteria,
  results,
  filters,
  paging,
  onUpdatePaging,
  onSelectItem,
  onShowItem,
  itemComponent,
  openedItemComponent,
}) => {
  const [openedItemIndex, setOpenedItemIndex] = useState(null);

  useEffect(() => {
    updateOpenedItemIndex();
  }, [onShowItem]);

  const updateOpenedItemIndex = () => {
    if (openedItemComponent) {
      let index = results.selectedIndex;
      if (index >= 0) {
        let item = document.getElementById(`results-item-${index}`);
        if (item) {
          const selectedItemTop = item.offsetTop;
          let currentElementTop = -1;
          while (item && currentElementTop <= selectedItemTop) {
            index += 1;
            item = document.getElementById(`results-item-${index}`);
            if (item) {
              currentElementTop = item.offsetTop;
            }
          }
        }
      }
      if (index !== openedItemIndex) {
        setOpenedItemIndex(index);
      }
    }
  };

  let selectedProps = null;

  const items = results.items.map((item, index) => {
    const selected = index === results.selectedIndex;

    const onPrev = prevFunc(index, paging, onUpdatePaging, onSelectItem);
    const onNext = nextFunc(
      index,
      results,
      paging,
      onUpdatePaging,
      onSelectItem
    );

    const props = {
      index,
      selectedIndex: results.selectedIndex,
      selectedBy: results.selectedBy,
      key: item.id,
      item,
      criteria: criteria,
      paging: paging,
      filters: filters,
      resultNumber: index + paging.pageSize * paging.page + 1, // eslint-disable-line
      selected,
      onShow: () => onShowItem(index),
      onSelect: () => onSelectItem(index),
      onUnselect: () => onSelectItem(-1),
      onSelectPrev: onPrev,
      onSelectNext: onNext,
    };
    if (selected) {
      selectedProps = props;
    }
    return React.createElement(itemComponent, props);
  });

  // Insert opened item to the calculated position
  if (openedItemIndex && selectedProps) {
    items.splice(
      openedItemIndex,
      0,
      React.createElement(openedItemComponent, {
        ...selectedProps,
        key: 'opened-item', // `opened-${selectedProps.key}`
      })
    );
  }

  return <StyledWrapper>{items}</StyledWrapper>;
};

const StyledWrapper = styled.div`
  padding: 16px 32px 8px 32px;
  margin-bottom: 150px;
`;

Results.propTypes = propTypes;

export default Results;
