import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';
import moment from 'moment';
import 'moment/locale/fi';

// Import some devtools
import { AppContainer } from 'react-hot-loader'; // eslint-disable-line
import { createLogger } from 'redux-logger'; // eslint-disable-line
import { createDevTools } from '@redux-devtools/core'; // eslint-disable-line
import LogMonitor from '@redux-devtools/log-monitor'; // eslint-disable-line
import DockMonitor from '@redux-devtools/dock-monitor'; // eslint-disable-line

import appReducer from './app/app.reducer';
import appSagas from './app/app.sagas';
import Root from './index.root'; // eslint-disable-line
import { history } from '~infra/browser.util';

// Init moment locale
moment.locale('fi');

// redux-first-history
const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history });

// Create store
const sagaMiddleware = createSagaMiddleware();
let store;
if (process.env.NODE_ENV === 'development') {
  // Development
  const logger = createLogger();
  const devtools = window.devToolsExtension
    ? window.devToolsExtension()
    : createDevTools(
        <DockMonitor
          toggleVisibilityKey="ctrl-h"
          changePositionKey="ctrl-w"
          defaultIsVisible={false}
        >
          <LogMonitor />
        </DockMonitor>
      ).instrument();
  store = createStore(
    appReducer(routerReducer),
    compose(
      applyMiddleware(routerMiddleware),
      applyMiddleware(sagaMiddleware, logger),
      devtools
    )
  );
} else {
  // Production
  store = createStore(
    appReducer(routerReducer),
    compose(applyMiddleware(routerMiddleware), applyMiddleware(sagaMiddleware))
  );
}
sagaMiddleware.run(appSagas);
store.dispatch({ type: 'COMMON/INIT_APP', payload: {} });

// where to mount on page
const appElement = document.getElementById('app');

// history object from redux-fist-history
const reduxHistory = createReduxHistory(store);

const renderApp = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <Component store={store} history={reduxHistory} />
    </AppContainer>,
    appElement
  );
};

if (process.env.NODE_ENV === 'development') {
  renderApp(Root);
  if (module.hot) {
    module.hot.accept('./app/app.container', () => {
      renderApp(Root);
    });
  }
} else {
  ReactDOM.render(<Root store={store} history={reduxHistory} />, appElement);
}
