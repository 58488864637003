import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';

const propTypes = {
  paging: PropTypes.shape({
    page: PropTypes.number.isRequired,
  }).isRequired,
  numOfPages: PropTypes.number.isRequired,
  onSelectPage: PropTypes.func.isRequired,
};

// TODO use select.component.js
const PageSelect = ({ paging, numOfPages, onSelectPage }) => {
  const pageInput = useRef();

  useEffect(() => {
    pageInput.current.min = 1;
  }, []);

  useEffect(() => {
    pageInput.current.value = paging.page + 1;
  }, [paging]);

  const onWheel = (event) => {
    const page = parseInt(pageInput.current.value, 10) || 1;
    pageInput.current.value = Math.max(1, page + Math.sign(event.deltaY));
  };

  const onClick = () => {
    pageInput.current.select();
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) onSelect();
  };

  const onSelect = () => {
    const page = Math.max(0, (parseInt(pageInput.current.value, 10) || 1) - 1);
    if (page === paging.page || page < 0) {
      resetValue();
      return;
    }
    onSelectPage(page);
  };

  const resetValue = () => {
    pageInput.current.value = paging.page + 1;
  };

  return (
    <Wrapper>
      <PageInput
        type="number"
        onClick={onClick}
        inputRef={pageInput}
        onKeyDown={onKeyDown}
        onBlur={resetValue}
        onWheel={onWheel}
      />
      /{numOfPages}
    </Wrapper>
  );
};

// Container style "cont > * { display: inline; }" would overrides this without !important
const Wrapper = styled.div`
  display: inline-flex !important;
  align-items: center;
`;

const PageInput = styled(TextField)`
  width: 80px;
`;

PageSelect.propTypes = propTypes;

export default PageSelect;
