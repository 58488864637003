import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';

const propTypes = {
  buttonText: PropTypes.string.isRequired,
  infoMessage: PropTypes.string.isRequired,
  copyContent: PropTypes.string.isRequired,
  buttonClass: PropTypes.any,
};

const styles = (theme) => ({
  close: {
    width: theme.spacing(1) * 4,
    height: theme.spacing(1) * 4,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    height: 16,
    width: 16,
  },
});

const CopyToClipboardButton = ({
  buttonText,
  infoMessage,
  copyContent,
  buttonClass,
  classes,
}) => {
  const [open, setOpen] = useState(false);

  const openSnackbar = () => setOpen(true);
  const closeSnackbar = () => setOpen(false);

  return (
    <span>
      {/* the button */}
      <Button
        variant={'contained'}
        color="primary"
        className={buttonClass}
        onClick={() => {
          navigator.clipboard.writeText(copyContent);
          openSnackbar();
        }}
      >
        {buttonText}
        <LinkIcon className={classes.rightIcon} />
      </Button>

      {/* info message snackbar */}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        // commented out because this just causes an error after clicking a copy button
        // SnackbarContentProps={{
        //   'aria-describedby': 'message-id',
        // }}
        message={<span id="message-id">{infoMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={closeSnackbar}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </span>
  );
};

CopyToClipboardButton.propTypes = propTypes;

export default withStyles(styles)(CopyToClipboardButton);
