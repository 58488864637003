import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Text from './text.component';

import SearchPage, {
  withMapStateToProps,
  withMapDispatchToProps,
} from '../common/searchPage.component.js';

export default withRouter(
  connect(
    withMapStateToProps('texts', Text),
    withMapDispatchToProps('texts')
  )(SearchPage)
);
