import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { lightBlue, red } from '@material-ui/core/colors';
import styled from 'styled-components';

import { common } from './common/common.ducks.js';
import AppBar from './appBar.component';
import LoginPage from './user/loginPage.container';
import PictureSearchPage from './search/pictures/pictureSearchPage.container';
import PictureViewPage from './search/pictures/pictureViewPage.container';
import TextSearchPage from './search/texts/textSearchPage.container';
import TextBrowsePage from './search/texts/textBrowsePage.container';
import TextViewPage from './search/texts/textViewPage.container';

const theme = createMuiTheme({
  palette: {
    primary: { ...lightBlue, contrastText: '#ffffff' },
    secondary: {
      ...red,
      A200: '#ff6a6a',
    },
  },
  overrides: {
    MuiButton: {
      // raisedPrimary: {
      //   color: "white",
      // },
      // raisedAccent: {
      //   color: "white",
      //   "&:hover": {
      //     backgroundColor: "#ff5252",
      //   },
      // },
      root: {
        disabled: {
          backgroundColor: '#e2e2e2 !important',
        },
      },
    },
    MuiCheckbox: {
      default: {
        height: '32px !important',
      },
    },
    MuiFormGroup: {
      root: {
        paddingTop: '0 !important',
        marginTop: '8px',
      },
    },
    MuiInput: {
      root: {
        fontSize: 'auto',
      },
    },
    MuiListItem: {
      root: {
        default: {
          paddingTop: '6px',
          paddingBottom: '6px',
        },
      },
    },
    MuiDrawer: {
      paper: {
        zIndex: '1000',
        position: 'relative',
      },
    },
    MuiChip: {
      root: {
        float: 'left',
        clear: 'left',
        maxWidth: '185px',
      },
      label: {
        display: 'inline-block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
      },
    },
    MuiAppBar: {
      positionFixed: {
        position: 'absolute',
      },
    },
  },
});

const App = ({ onToggleDrawer }) => {
  const location = useLocation();
  const pathname = location.pathname;

  const user = useSelector((state) => state.common.user);
  return (
    <MuiThemeProvider theme={theme}>
      <StyledWrapper>
        <StyledInnerWrapper>
          {pathname !== '/picture' && (
            <AppBar
              pathname={pathname}
              onToggleDrawer={onToggleDrawer}
              user={user}
            />
          )}
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/pictures" component={PictureSearchPage} />
          <Route
            exact
            path="/picture"
            render={(props) => <PictureViewPage {...props} user={user} />}
          />
          <Route exact path="/texts" component={TextSearchPage} />
          <Route exact path="/texts/browse" component={TextBrowsePage} />
          <Route
            exact
            path="/text/:database/:itemId"
            component={TextViewPage}
          />
        </StyledInnerWrapper>
      </StyledWrapper>
    </MuiThemeProvider>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 24:
  z-index: 1:
  overflow: hidden;

  @media print {
    margin-top: 0;

    button {
      display: none !important;
    }
  }
`;

const StyledInnerWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  @media print {
    display: block !important;
  }
`;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onToggleDrawer: common.toggleDrawer,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
