import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Article from './article.component';
import ErrorBoundary from '~infra/errorBoundary.component.js';
import { search, withSection } from '~search/search.ducks';
import KeyListener from '~search/keyListener.component';
import { prevFunc, nextFunc } from '~search/search.util';

const propTypes = {
  results: PropTypes.object.isRequired,
  paging: PropTypes.object.isRequired,
  onUpdatePaging: PropTypes.func.isRequired,
  onShowItem: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  rightIcon: {
    marginLeft: theme.spacing(1),
    height: 16,
    width: 16,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

const TextBrowsePage = ({
  history,
  results,
  paging,
  onUpdatePaging,
  onShowItem,
  classes,
}) => {
  const onGoBack = () => {
    history.push('/texts');
  };

  const item = results.items[results.selectedIndex];
  const onPrev = prevFunc(
    results.selectedIndex,
    paging,
    onUpdatePaging,
    onShowItem
  );
  const onNext = nextFunc(
    results.selectedIndex,
    results,
    paging,
    onUpdatePaging,
    onShowItem
  );

  const articleNumber =
    paging.page * paging.pageSize + results.selectedIndex + 1;

  return (
    <ErrorBoundary>
      <KeyListener
        listenEsc={false}
        results={results}
        paging={paging}
        onUpdatePaging={onUpdatePaging}
        onSelectItem={onShowItem}
      />
      <Article
        item={item}
        headlinePrefix={`${articleNumber}.`}
        onGoBack={onGoBack}
        classes={classes}
      >
        <Button variant={'contained'} color="primary" onClick={onGoBack}>
          <NavigateBeforeIcon className={classes.leftIcon} />
          Takaisin hakutuloksiin
        </Button>
        <Spacer />
        <Button
          variant={'contained'}
          color="primary"
          disabled={!onPrev}
          onClick={onPrev}
        >
          <NavigateBeforeIcon />
        </Button>
        <Spacer />
        <Button
          variant={'contained'}
          color="primary"
          disabled={!onNext}
          onClick={onNext}
        >
          <NavigateNextIcon />
        </Button>
      </Article>
    </ErrorBoundary>
  );
};

const Spacer = styled.div`
  margin: 5px;
`;

export const mapStateToProps = (state) => {
  return {
    results: state.search.texts.results,
    paging: state.search.texts.paging,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actionCreators = bindActionCreators(
    {
      onUpdatePaging: search.updatePaging,
      onShowItem: search.showItem,
    },
    dispatch
  );
  return {
    onUpdatePaging: withSection('texts', actionCreators.onUpdatePaging),
    onShowItem: withSection('texts', actionCreators.onShowItem),
  };
};

TextBrowsePage.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(TextBrowsePage),
  withRouter(TextBrowsePage)
);
