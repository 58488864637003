import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import AppContainer from "./app/app.container";
import Listeners from "./index.listeners";

const Root = ({ store, history }) => (
  <React.Fragment>
    <Listeners />
    <Provider store={store}>
      <Router history={history}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AppContainer />
        </MuiPickersUtilsProvider>
      </Router>
    </Provider>
  </React.Fragment>
);

export default Root;
