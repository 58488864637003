import queryString from 'query-string';
import { asItemImageUrl } from '../common/item.api';

const PictureViewPage = ({ user }) => {
  const params = queryString.parse(location.search);
  const hash = location.hash;
  if (params.database && hash && user) {
    const dbInfo = params.database.split('/');
    const item = {
      db: dbInfo[0],
      dbType: dbInfo[1],
      id: hash.substring(1),
      fields: params,
    };

    window.location.replace(asItemImageUrl(item, 'Original'));
  }

  return null;
};

export default PictureViewPage;
