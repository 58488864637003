import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppBar as MDAppBar, Toolbar, Tabs, Tab } from '@material-ui/core';
import logo from '../assets/sanoma_logo.png';

import UserMenu from './user/userMenu.container';
import { getSivuarkistoUrl } from '~infra/api.util';

const tabValuesByPathname = {
  '-': false,
  '/page': 0,
  '/text': 1,
  '/pict': 2,
};

const titlesByPathname = {
  '-': 'HOLVI',
  '/logi': 'Kirjaudu - HOLVI',
  '/page': 'Sivut - HOLVI',
  '/text': 'Tekstit - HOLVI',
  '/pict': 'Kuvat - HOLVI',
};

const sivuarkistoUrl = getSivuarkistoUrl();

const AppBar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const pathKey =
    (pathname && pathname.length >= 5 && pathname.substring(0, 5)) || '-';
  document.title = titlesByPathname[pathKey];
  const user = useSelector((state) => state.common.user);
  return (
    <StyledAppBar>
      <Toolbar>
        <SanomaLogo src={logo} alt="sanoma" />
        <ArkistoLogo>HOLVI</ArkistoLogo>
        <TabsWrapper value={tabValuesByPathname[pathKey]}>
          <Tab label="Sivut" component="a" href={sivuarkistoUrl} />
          <Tab label="Tekstit" disabled={!user} component={Link} to="/texts" />
          <Tab label="Kuvat" disabled={!user} component={Link} to="/pictures" />
        </TabsWrapper>
        {user && <UserMenu />}
      </Toolbar>
    </StyledAppBar>
  );
};

const StyledAppBar = styled(MDAppBar)`
  > div {
    color: #ffffff;
    min-height: 48px;
  }

  @media print {
    display: none !important;
  }
`;

/* eslint-disable */
const SanomaLogo = styled.img`
  width: 100px;
  height: auto;
  vertical-align: middle;
  cursor: pointer;
  margin: 0 8px;

  @media (max-width: 640px) {
    display: none;
  }
`;
/* eslint-enable */

const ArkistoLogo = styled.div`
  margin: 0 27px 0 4px;
  letter-spacing: 3px;
  font-family: 'Questrial', 'Roboto', 'Source Sans Pro', sans-serif;
  @media (max-width: 640px) {
    display: none;
  }
`;

const TabsWrapper = styled(Tabs)`
  flex: 10;
  display: flex;
  a:hover {
    text-decoration: none !important;
  }
`;

export default AppBar;
