import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Paper, TextField, FormControl, Button } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { user } from './user.ducks';
import Gutter from '~common/components/Gutter';
import ErrorBoundary from '~infra/errorBoundary.component.js';

const propTypes = {
  onLogin: PropTypes.func.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  loginError: PropTypes.shape({
    credentials: PropTypes.bool,
    somethingElse: PropTypes.bool,
  }),
};

const LoginPage = ({ onLogin, loginError, loggingIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const login = (e) => {
    e.preventDefault();
    onLogin(username, password);
  };

  const handleUsernameInput = (e) => setUsername(e.target.value);
  const handlePasswordInput = (e) => setPassword(e.target.value);

  return (
    <ErrorBoundary>
      <StyledMain>
        {/* TODO wrap inside view/errorBoundary */}
        <StyledPaper>
          <StyledForm>
            <StyledFormControl>
              <TextField
                error={loginError && loginError.credentials}
                autoFocus
                label="Käyttäjätunnus"
                value={username}
                onChange={handleUsernameInput}
              />
            </StyledFormControl>
            <StyledFormControl>
              <TextField
                error={loginError && loginError.credentials}
                label="Salasana"
                type="password"
                value={password}
                onChange={handlePasswordInput}
              />
            </StyledFormControl>
            <Gutter vertical amount="32px" />
            <StyledFormControl>
              <Button
                type="submit"
                color="primary"
                onClick={login}
                onSubmit={login}
              >
                {loggingIn ? 'Kirjaudutaan..' : 'Kirjaudu sisään'}
              </Button>
            </StyledFormControl>
            {loginError.somethingElse && (
              <LoginError>
                Kirjautuminen epäonnistui. Yritä myöhemmin uudelleen.
              </LoginError>
            )}
            {loginError.credentials && (
              <LoginError>Virheellinen käyttäjätunnus tai salasana!</LoginError>
            )}
          </StyledForm>
        </StyledPaper>
      </StyledMain>
    </ErrorBoundary>
  );
};

const StyledMain = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 296px;
  padding: 32px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledFormControl = styled(FormControl)`
  > div {
    margin: 8px 0;
  }
`;

const LoginError = withTheme(styled.span`
  margin-top: 10px;
  text-align: center;
  color: ${(props) => props.theme.palette.error.A200};
`);

const mapStateToProps = (state) => {
  return {
    loggingIn: state.user.loggingIn,
    loginError: state.user.loginError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onLogin: user.login,
    },
    dispatch
  );
};

LoginPage.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
