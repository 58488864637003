import React from 'react';
import styled from 'styled-components';
import {
  Button,
  Chip,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  RadioGroup,
  Radio,
  Select,
  withStyles,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';

import { magazinesBySection } from './search.constants';

const add = (array, value) => {
  return (array || []).concat(value);
};

const remove = (array, value) => {
  return (array || []).filter((itemValue) => itemValue !== value);
};

const contains = (array, value) =>
  !!(array || []).find((itemValue) => itemValue === value);

const label = (array, value) => {
  const option = array && array.find((item) => item.value === value);
  return option ? option.title : value;
};

const styles = (theme) => ({
  database_0: {
    fontWeight: 'bold',
  },
  database_1: {
    paddingLeft: '30px',
  },
  magazineSection: {
    fontWeight: 'bold',
  },
  magazineItem: {
    paddingLeft: '40px',
  },
  input: {
    backgroundColor: '#21282f',
    border: '0',
    borderRadius: '2px 2px 0px 0px',
    paddingTop: '4px',
    paddingLeft: '8px',
    marginTop: '2px',
    marginBottom: '10px',
    fontSize: '13px !important',
    width: '100%',
  },
  formLabel: {
    fontSize: '14px !important',
  },
  gutter: {
    marginBottom: '8px',
  },
  formControl: {
    display: 'inline',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    height: 20,
    width: 20,
  },
});

const SearchDrawer = ({
  drawerVisible,
  section,
  databases,
  inputValues,
  onUpdateInputValue,
  criteria,
  criteriaOptions,
  onUpdateCriteria,
  filters,
  onUpdateFilter,
  onClear,
  classes,
}) => (
  <StyledDrawer variant="persistent" open={drawerVisible}>
    <Content>
      <Forms>
        {/* Database select */}

        <FormGroup className={classes.gutter}>
          <FormLabel
            component="legend"
            htmlFor="database"
            className={classes.formLabel}
          >
            Tietokanta
          </FormLabel>
          <StyledFormControl>
            {/* TODO read from server */}
            <Select
              value={inputValues.database || '-'}
              onChange={(e) => onUpdateInputValue('database', e.target.value)}
              className={classes.input}
            >
              {databases.map((db) => (
                <DBMenuItem
                  key={db.key}
                  value={db.id}
                  className={classes[`database_${db.level}`]}
                >
                  {db.title}
                  <DBName>{db.name}</DBName>
                </DBMenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </FormGroup>

        {/* Magazine select */}

        <FormGroup className={classes.gutter}>
          <FormLabel
            component="legend"
            htmlFor="magazines"
            className={classes.formLabel}
          >
            Julkaistu lehdessä
          </FormLabel>
          <StyledFormControl className={classes.formControl}>
            <Select
              value=""
              onChange={(e) =>
                onUpdateInputValue(
                  'magazines',
                  add(inputValues.magazines, e.target.value)
                )
              }
              className={classes.input}
              displayEmpty
            >
              {Object.keys(magazinesBySection[section]).map((sectionTitle) => [
                <MenuItem
                  disabled
                  className={classes.magazineSection}
                  key={sectionTitle}
                >
                  {sectionTitle}
                </MenuItem>,

                Object.keys(magazinesBySection[section][sectionTitle]).map(
                  (itemTitle) => (
                    <MenuItem
                      className={classes.magazineItem}
                      key={itemTitle}
                      value={itemTitle}
                    >
                      {itemTitle}
                    </MenuItem>
                  )
                ),
              ])}
            </Select>
            {/* Magazine chips */}
            {inputValues.magazines &&
              !!inputValues.magazines.length &&
              inputValues.magazines.map((value) => (
                <StyledChip
                  key={value}
                  label={value}
                  onDelete={() =>
                    onUpdateInputValue(
                      'magazines',
                      remove(inputValues.magazines, value)
                    )
                  }
                />
              ))}
          </StyledFormControl>
        </FormGroup>

        {/* Photographer select */}

        {section === 'pictures' && (
          <FormGroup className={classes.gutter}>
            <FormLabel
              component="legend"
              htmlFor="photographers"
              className={classes.formLabel}
            >
              Kuvaaja
            </FormLabel>
            <StyledFormControl className={classes.formControl}>
              <Select
                value=""
                onChange={(e) =>
                  onUpdateInputValue(
                    'photographers',
                    add(inputValues.photographers, e.target.value)
                  )
                }
                className={classes.input}
              >
                {criteriaOptions.photographers &&
                  criteriaOptions.photographers.map(
                    (option) =>
                      !contains(inputValues.photographers, option.value) && (
                        <MenuItem key={option.value} value={option.value}>
                          {option.title}
                        </MenuItem>
                      )
                  )}
              </Select>
              {/* Photographer chips */}
              {inputValues.photographers &&
                !!inputValues.photographers.length &&
                inputValues.photographers.map((value) => (
                  <StyledChip
                    key={value}
                    label={`${label(criteriaOptions.photographers, value)}`}
                    onDelete={() =>
                      onUpdateInputValue(
                        'photographers',
                        remove(inputValues.photographers, value)
                      )
                    }
                  />
                ))}
            </StyledFormControl>
          </FormGroup>
        )}

        {/* Date range */}

        <FormGroup className={classes.gutter}>
          <StyledFormControl>
            <FormLabel
              component="legend"
              htmlFor="startDate"
              className={classes.formLabel}
            >
              Alkupäivä (arkistointipvm)
            </FormLabel>
            <StyledDatePicker
              format="DD.MM.YYYY"
              disableFuture
              clearable
              clearLabel="Tyhjennä"
              cancelLabel="Peruuta"
              invalidLabel=""
              invalidDateMessage="Puutteellinen päivämäärä"
              value={inputValues.startDate || null}
              onChange={(date) => onUpdateInputValue('startDate', date)}
            />
          </StyledFormControl>
          <StyledFormControl>
            <FormLabel
              component="legend"
              htmlFor="endDate"
              className={classes.formLabel}
            >
              Loppupäivä (arkistointipvm)
            </FormLabel>
            <StyledDatePicker
              format="DD.MM.YYYY"
              disableFuture
              clearable
              clearLabel="Tyhjennä"
              cancelLabel="Peruuta"
              invalidLabel=""
              invalidDateMessage="Puutteellinen päivämäärä"
              value={inputValues.endDate || null}
              onChange={(date) => onUpdateInputValue('endDate', date)}
            />
          </StyledFormControl>

          {/* SWITCHING DATE TYPE FOR SEARCH DISABLED FOR THE MOMENT, might be
          taken back into use later */
          /* section === 'pictures' &&
            (inputValues.startDate || inputValues.endDate) && (
              <StyledFormControl>
                <StyledRadioGroup
                  value={inputValues.dateType}
                  onChange={e => onUpdateInputValue('dateType', e.target.value)}
                >
                  <StyledFormControlLabel
                    value='all'
                    control={<Radio />}
                    label='Mikä tahansa päivä'
                  />
                  <StyledFormControlLabel
                    value='archiveDate'
                    control={<Radio />}
                    label='Arkistointipäivä'
                  />
                  <StyledFormControlLabel
                    value='shootingDate'
                    control={<Radio />}
                    label='Kuvauspäivä'
                  />
                  <StyledFormControlLabel
                    value='publishDate'
                    control={<Radio />}
                    label='Julkaisupäivä'
                  />
                </StyledRadioGroup>
              </StyledFormControl>
            ) */}
        </FormGroup>

        {/* Picture shape */}

        <FormGroup>
          {section === 'pictures' && (
            <StyledFormControl>
              <FormLabel
                component="legend"
                htmlFor="endDate"
                className={classes.formLabel}
              >
                Kuvan muoto
              </FormLabel>
              <StyledRadioGroup
                value={filters.shape || '-'}
                onChange={(e) => onUpdateFilter('shape', e.target.value)}
              >
                <StyledFormControlLabel
                  value="-"
                  control={<Radio />}
                  label="Kaikki"
                />
                <StyledFormControlLabel
                  value="landscape"
                  control={<Radio />}
                  label="Vain vaaka"
                />
                <StyledFormControlLabel
                  value="portrait"
                  control={<Radio />}
                  label="Vain pysty"
                />
              </StyledRadioGroup>
            </StyledFormControl>
          )}
        </FormGroup>
      </Forms>
      <Footer>
        <StyledFormControl>
          <Button
            variant={'contained'}
            color="primary"
            onClick={() =>
              onUpdateCriteria('simpleText', inputValues.simpleText, true)
            }
          >
            Tee haku
            <SearchIcon className={classes.rightIcon} />
          </Button>
          <Button
            variant={'contained'}
            onClick={() =>
              onClear({
                inputValues: { simpleText: inputValues.simpleText },
                criteria: { simpleText: criteria.simpleText },
              })
            }
          >
            Tyhjennä ehdot
          </Button>
        </StyledFormControl>
      </Footer>
    </Content>
  </StyledDrawer>
);

/* Styling for DatePicker is a bit hacky b/c the component itself is tricky to
work with */
const StyledDatePicker = styled(KeyboardDatePicker)`
  margin-top: 8px !important;
  border: 0;
  padding-top: 4px;
  font-size: 13px !important;
  min-height: 33px;

  /* Error message */
  > p {
    bottom: -12px;
    font-size: 9px;
  }

  /* FormControl */
  > div {
    height: 33px;
    background-color: #21282f;
    border-radius: 2px 2px 0px 0px;
  }

  /* Input */
  > div > input {
    padding-left: 8px;
    position: absolute;
    top: 4px;
    width: 80%;
  }

  /* Icon Wrapper */
  > div > div {
    position: absolute;
    right: -5px;
  }

  /* Icon Button */
  > div > div > button {
    height: 40px;
    width: 40px;
  }

  /* Icon size */
  > div > div > button > span > span {
    font-size: 20px;
  }
`;

const StyledDrawer = styled(Drawer)`
  z-index: 1000 !important;
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};

  @media print {
    display: none;
  }
`;

const StyledFormControl = styled(FormControl)`
  margin: 8px 0 !important;
  width: 100%;
  max-width: 176px; /* firefox fix */
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  > span:first-child {
    height: 32px;
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  padding-top: 16px;
`;

const StyledChip = styled(Chip)`
  margin: 0px 4px 8px !important;
  background-color: #03a9f4 !important;
  font-size: 12px !important;
`;

const Content = styled.div`
  width: 240px;
  margin-top: 48px;
  background-color: #29323b;
  height: calc(100vh - 48px) !important;
  overflow: auto;
`;

const Forms = styled.div`
  position: relative;
  padding: 16px 32px 0px;
  height: -webkit-calc(100% - 115px);
  height: -moz-calc(100% - 115px);
  height: calc(100% - 115px);
  overflow-y: auto;
  overflow-x: hidden;

  /* TODO use MD-theme and remove these color hacks */
  * {
    color: #ffffff !important;
  }
  .error-message {
    color: #fd6b6d !important;
    font-size: 0.875rem;
  }
`;

const Footer = styled.div`
  background-color: #29323b;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 5px 32px 0px;
  height: 115px;
  z-index: 1;
  border-top: 2px solid #21282f;
  > div > button {
    margin-bottom: 8px;
  }
`;

const DBMenuItem = styled(MenuItem)`
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  &:hover > div {
    display: block;
  }
`;

// use display: none instead of visibility: hidden
// because latter breaks the select input
// as a side-effect there is annoying resizing of the
// select list when hovering over options
const DBName = styled.div`
  display: none;
  width: auto;
  background-color: rgba(0, 0, 0, 0.15);
  color: #fff;
  text-align: center;
  padding: 5px 8px;
  margin-left: 10px;
  z-index: 1;
  font-size: 12px;
`;

export default withStyles(styles)(SearchDrawer);
