import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Picture from './picture.component';
import SelectedPicture from './selectedPicture.component';

import SearchPage, {
  withMapStateToProps,
  withMapDispatchToProps,
} from '../common/searchPage.component.js';

export default withRouter(
  connect(
    withMapStateToProps('pictures', Picture, SelectedPicture),
    withMapDispatchToProps('pictures')
  )(SearchPage)
);
