import { combineReducers } from 'redux';

import { userReducer as user } from './user/user.ducks';
import { commonReducer as common } from './common/common.ducks.js';
import { searchReducer as search } from './common/search/search.ducks.js';

// App reducer
const appReducer = (routerReducer) =>
  combineReducers({
    user,
    common,
    search,
    router: routerReducer,
  });

export default appReducer;
