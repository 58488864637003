import { fork, takeEvery } from 'redux-saga/effects';

import { searchSagas, searchSagasInit } from './common/search/search.sagas';
import * as itemApi from './search/common/item.api';
import userSagas, { sectionsByPath } from './user/user.sagas';

const errorHandler = (err) => {
  if (err.statusCode === 403) location.reload();
  if (err.response && err.response.status === 403) location.reload();
};

searchSagasInit(
  // sectionsByPath
  sectionsByPath,
  // apisBySection
  {
    pictures: itemApi,
    texts: itemApi,
  },
  // criteriaTypeByName
  {
    photographers: 'array',
    magazines: 'array',
  },
  errorHandler
);

function* watchDebug() {
  yield takeEvery('DEBUG', (action) =>
    console.debug('[REDUX DEBUG]', action.payload)
  );
}

export default function* root() {
  yield fork(watchDebug);
  yield fork(searchSagas);
  yield fork(userSagas);
}
