import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, Button, IconButton } from '@material-ui/core';

const propTypes = {
  label: PropTypes.string,
  icon: PropTypes.any, // TODO use node or element instead?
  color: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
};

const MenuButton = ({ label, icon, color, options, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => setOpen(false);

  const select = (value) => {
    handleRequestClose();
    onSelect(value);
  };

  return (
    <span>
      {label && (
        <Button color={color} onClick={handleClick}>
          {label}
          {icon && React.createElement(icon)}
        </Button>
      )}
      {!label && (
        <IconButton color={color} onClick={handleClick}>
          {React.createElement(icon)}
        </IconButton>
      )}
      <Menu anchorEl={anchorEl} open={open} onClose={handleRequestClose}>
        {options &&
          options.map((option) => (
            <MenuItem key={option.value} onClick={() => select(option.value)}>
              {option.title}
            </MenuItem>
          ))}
      </Menu>
    </span>
  );
};

MenuButton.propTypes = propTypes;

export default MenuButton;
