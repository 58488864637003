import axios from 'axios';

// TODO use some existing logger / exception handler lib?
const logger = {
  log: (ex) => {
    console.log(ex);
  },
  warn: (ex) => {
    console.warn(ex);
  },
  error: (ex, context) => {
    if (context) {
      console.log(JSON.stringify(context));
    }
    console.error(ex);
  },
};

// Setup global exception handling for axios
axios.interceptors.response.use(null, (error) => {
  if (!error.response || error.response.status >= 500) {
    logger.error(error);
  }
  return Promise.reject(error);
});

export default logger;
