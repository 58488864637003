import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

import FirstPageIcon from '@material-ui/icons/FirstPage';
import PrevPageIcon from '@material-ui/icons/NavigateBefore';
import NextPageIcon from '@material-ui/icons/NavigateNext';
import LastPageIcon from '@material-ui/icons/LastPage';

import PageSelect from './pageSelect.component.js';

const propTypes = {
  paging: PropTypes.shape({
    page: PropTypes.number.isRequired,
  }).isRequired,
  numOfPages: PropTypes.number.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  listenKeys: PropTypes.bool,
};

const pagingControlKeyPressed = (event) => {
  return navigator.userAgent.indexOf(' Mac ') !== -1
    ? event.altKey
    : event.ctrlKey;
};

const Paging = ({
  paging,
  numOfPages,
  onSelectPage,
  listenKeys,
  bottom,
  drawerVisible,
}) => {
  useEffect(() => {
    if (listenKeys) {
      document.addEventListener('keydown', keydownListener, false);
      return () => {
        document.removeEventListener('keydown', keydownListener, false);
      };
    }
  }, []);

  const prevButton = useRef(null);
  const nextButton = useRef(null);

  const keydownListener = (event) => {
    /* eslint-disable */
    if (
      pagingControlKeyPressed(event) &&
      event.keyCode === 37 /* ArrowLeft */
    ) {
      prevButton.current && prevButton.current.click();
    }
    if (
      pagingControlKeyPressed(event) &&
      event.keyCode === 39 /* ArrowRight */
    ) {
      nextButton.current && nextButton.current.click();
    }
    /* eslint-enable */
  };

  const selectFirstPage = () => {
    onSelectPage(0);
  };

  const selectPrevPage = () => {
    onSelectPage(paging.page - 1);
  };

  const selectNextPage = () => {
    onSelectPage(paging.page + 1);
  };

  const selectLastPage = () => {
    onSelectPage(numOfPages - 1);
  };

  return (
    <PagerWrapper flex={10} bottom={bottom} drawerVisible={drawerVisible}>
      <IconButton
        color="primary"
        disabled={paging.page <= 0}
        onClick={selectFirstPage}
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        ref={prevButton}
        color="primary"
        disabled={paging.page <= 0}
        onClick={selectPrevPage}
      >
        <PrevPageIcon />
      </IconButton>
      <PageSelect
        paging={paging}
        onSelectPage={onSelectPage}
        numOfPages={numOfPages}
      />
      <IconButton
        ref={nextButton}
        color="primary"
        disabled={paging.page >= numOfPages - 1}
        onClick={selectNextPage}
      >
        <NextPageIcon />
      </IconButton>
      <IconButton
        color="primary"
        disabled={paging.page >= numOfPages - 1}
        onClick={selectLastPage}
      >
        <LastPageIcon />
      </IconButton>
    </PagerWrapper>
  );
};

const PagerWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: left !important;
  flex: none !important;

  /* Pager buttons */
  > * {
    margin-right: 8px;
    display: inline-block;
  }
`;

Paging.propTypes = propTypes;

export default Paging;
