import React from 'react';
import styled from 'styled-components';

const Info = (props) => (
  <InfoWrapper>
    <span>{!!props.sis && props.sis[0] !== '' && props.sis}</span>
    <br />
    {!!props.kja && props.kja[0] !== '' && (
      <span>
        <b>Kuvaaja: </b>
        {props.kja}
        <br />
      </span>
    )}
    {!!props.kpv && props.kpv[0] !== '' && (
      <span>
        <b>Kuvauspvm: </b>
        {props.kpv}
        <br />
      </span>
    )}
    {!!props.julk && props.julk[0] !== '' && (
      <span>
        <b>Julkaistu: </b>
        {props.julk}
        <br />
      </span>
    )}
    {!!props.oik && props.oik[0] !== '' && (
      <span>
        <b>Tekijänoikeus: </b>
        {props.oik}
      </span>
    )}
  </InfoWrapper>
);

const InfoWrapper = styled.div``;

export default Info;
