import { createActions, handleActions } from 'redux-actions';

// Default state
const defaultState = {
  loggingIn: false,
  loginError: {
    credentials: false,
    somethingElse: false,
  },
};

// Actions
export const { user } = createActions({
  USER: {
    LOGIN: (username, password) => ({ username, password }),
    LOGIN_ERROR: () => ({}),
    AFTER_LOGIN: () => ({}),
    LOGOUT: () => ({}),
  },
});

// Reducer
export const userReducer = handleActions(
  {
    [user.login](state) {
      return {
        ...state,
        loggingIn: true,
        loginError: { credentials: false, somethingElse: false },
      };
    },
    [user.loginError](state, { payload: { credentials, somethingElse } }) {
      return {
        ...state,
        loggingIn: false,
        loginError: { credentials, somethingElse },
      };
    },
    [user.logout]() {
      return defaultState;
    },
  },
  defaultState
);
