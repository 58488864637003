import React from 'react';
import styled from 'styled-components';
import { IconButton, withStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Layout from '~common/components/Layout';

const styles = (theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

// TODO simpleText only

const SearchBar = ({ inputValues, onUpdateInputValue, onUpdateCriteria }) => (
  <Layout style={{ flex: 10, width: '100%' }}>
    <StyledInput
      type="text"
      placeholder="Hae"
      value={inputValues.simpleText || ''}
      onChange={(e) => onUpdateInputValue('simpleText', e.target.value)}
      onKeyPress={(e) =>
        e.key === 'Enter' &&
        onUpdateCriteria('simpleText', e.target.value, true)
      }
    />
    <IconButton
      color="primary"
      onClick={() =>
        onUpdateCriteria('simpleText', inputValues.simpleText, true)
      }
    >
      <SearchIcon />
    </IconButton>
  </Layout>
);

const StyledInput = styled.input`
  flex: 1;
  max-width: 600px;
  min-width: 80px;
  margin: 9px 0 8px 0;
  padding: 0 8px;
`;

export default withStyles(styles)(SearchBar);
