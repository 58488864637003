import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Paper } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { lightBlue } from '@material-ui/core/colors';

import Circle from './circle.component';
import Info from './info.component';
import { asItemImageUrl } from '../common/item.api.js';
import { appear } from '~misc/animations.util';

const propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number,
  // selectedBy: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onUnselect: PropTypes.func.isRequired,
  resultNumber: PropTypes.number.isRequired,
};

const styles = () => ({
  rounded: {
    borderRadius: '4px',
  },
});

class Picture extends Component {
  state = {
    hover: false,
    width: 130,
    height: 0,
  };

  // Ask jukka about this
  UNSAFE_componentWillReceiveProps(newProps) {
    // Automatically select next or previous picture if this picture
    // is selected but disabled
    // NOTE: This is a hack. Save image width/height to store instead of state
    // so that this can be handled in an action.
    if (
      newProps.selected &&
      newProps.selectedBy === 'key' &&
      this.isDisabled(newProps.filters.shape)
    ) {
      const forwards = this.props.selectedIndex < newProps.index;
      if (forwards && this.props.onSelectNext) {
        this.props.onSelectNext(newProps.selectedBy);
      } else if (!forwards && this.props.onSelectPrev) {
        this.props.onSelectPrev(newProps.selectedBy);
      } else {
        this.props.onUnselect();
      }
    }
  }

  updateImageProportions = (e) => {
    this.setState({
      width: e.target.clientWidth,
      height: e.target.clientHeight,
    });
  };

  isDisabled = (shape) => {
    return (
      (shape === 'landscape' && this.state.height > this.state.width) ||
      (shape === 'portrait' && this.state.height < this.state.width)
    );
  };

  mouseEnter = () => {
    this.setState({ hover: true });
  };

  /* Scroll content of shortInfo to top when mouse leaves component (for when
  the user has scrolled the content) */
  mouseLeave = () => {
    this.setState({ hover: false });
  };

  showShortInfo = () => {
    return !this.state.hover || this.props.selectedIndex !== -1;
  };

  render() {
    const { classes } = this.props;
    const disabled = this.isDisabled(this.props.filters.shape);
    return (
      <PictureWrapper
        id={`results-item-${this.props.index}`}
        onClick={
          this.mouseLeave &&
          (this.props.selected ? this.props.onUnselect : this.props.onSelect)
        }
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
        className={classes.rounded}
      >
        <ThumbContent>
          {/* TODO use thumbnail type or add image crop settings? */}
          <ThumbnailImageWrapper disabled={disabled}>
            <ThumbnailImage
              src={asItemImageUrl(this.props.item, 'Preview')}
              alt={this.props.item.name}
              onLoad={this.updateImageProportions}
            />
            <ResultNumber>{this.props.resultNumber}</ResultNumber>
            <DownloadButton
              aria-label="Lataa"
              size="small"
              color="inherit"
              onClick={(e) => {
                e.stopPropagation();
                window.location = asItemImageUrl(
                  this.props.item,
                  'Original',
                  true
                );
              }}
            >
              <GetAppIcon />
            </DownloadButton>
            <ColorCodes>
              {/* Show "copyright/published" circles only if field exists and
                and is not empty */}
              {!!this.props.item.fields.JULK &&
                this.props.item.fields.JULK[0].length !== 0 && (
                  <Circle color="#ffed0f" text="Dokumentti on julkaistu." />
                )}
              {!!this.props.item.fields.KO &&
                this.props.item.fields.KO[0].length !== 0 && (
                  <Circle
                    color="#ff421f"
                    text="Käyttöoikeus-kentässä on tekstiä, tarkista myös Tekijänoikeus ja Huom -kentät." // eslint-disable-line
                  />
                )}
            </ColorCodes>
          </ThumbnailImageWrapper>
          {this.showShortInfo() && (
            <ShortInfo width={this.state.width} disabled={disabled}>
              <Info
                sis={this.props.item.fields.SIS}
                kja={this.props.item.fields.KJA}
                kpv={this.props.item.fields.KPV}
                julk={this.props.item.fields.JULK}
                oik={this.props.item.fields.OIK}
              />
            </ShortInfo>
          )}
          {!this.showShortInfo() && (
            <LongInfo width={this.state.width} disabled={disabled}>
              <Info
                sis={this.props.item.fields.SIS}
                kja={this.props.item.fields.KJA}
                kpv={this.props.item.fields.KPV}
                julk={this.props.item.fields.JULK}
                oik={this.props.item.fields.OIK}
              />
            </LongInfo>
          )}
        </ThumbContent>
        {this.props.selected && (
          <SelectedMarker>
            <SelectedMarkerTringle />
          </SelectedMarker>
        )}
      </PictureWrapper>
    );
  }
}

const PictureWrapper = styled(Paper)`
  display: inline-block;
  vertical-align: top;
  width: auto;
  height: 218px;
  min-width: 160px;
  margin: 0 12px 12px 0;
  animation: ${appear} 0.8s forwards;
  background-color: #29323b !important;
  color: #ffffff !important;
  font-size: 11px;
  line-height: 1.4;
  position: relative;
  z-index: 0;
  cursor: pointer;

  &:hover {
    z-index: 1;
  }

  @media (max-width: 480px) {
    width: 100%;
  }

  @media print {
    display: none;
  }
`;

const SelectedMarker = styled.div`
  width: 100%;
  position: absolute;
  bottom: -16px;
`;

const SelectedMarkerTringle = styled.div`
  width: 0;
  height: 0;
  margin: 0 auto;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #29323b;
`;

const ThumbContent = styled.span`
  background-color: #29323b;
`;

const ThumbnailImageWrapper = styled.span`
  display: block;
  opacity: ${(props) => (props.disabled ? '0.3' : '1')};
`;

const ThumbnailImage = styled.img`
  width: ${(props) => props.width}px;
  height: 160px;
  margin: 0 auto;
  display: block;
  border-radius: 4px 4px 0px 0px;
`;

const ResultNumber = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 0px 4px 0px;
`;

const DownloadButton = styled(IconButton)`
  && {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 4px 0 4px;
    background: rgba(0, 0, 0, 0.5);
    height: 24px;
    width: 24px;
    color: white;

    .MuiSvgIcon-root {
      font-size: 1rem;
    }
  }

  &:hover,
  &:active {
    .MuiSvgIcon-root {
      color: ${lightBlue['600']};
    }
  }
`;

const ColorCodes = styled.div`
  position: absolute;
  top: 144px;
  left: 0;
  min-width: 10px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 3px;
`;

const ShortInfo = styled.div`
  width: ${(props) => props.width}px;
  min-width: 160px;
  height: calc(11px * 1.4 * 3.2);
  margin-top: 0;
  margin-bottom: 6px;
  padding: 6px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* max lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  opacity: ${(props) => (props.disabled ? '0.3' : '1')};
`;

const LongInfo = styled.div`
  width: ${(props) => props.width}px;
  min-width: 160px;
  height: auto;
  margin-top: 0;
  padding: 6px;
  background-color: #29323b !important;
  border-radius: 0px 0px 4px 4px;

  > * {
    opacity: ${(props) => (props.disabled ? '0.3' : '1')};
  }
`;

Picture.propTypes = propTypes;

export default withStyles(styles)(Picture);
