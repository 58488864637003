import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
// import PersonIcon from '@material-ui/icons/Person';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { user as userActions } from './user.ducks';
import MenuButton from '~controls/menuButton.component';

const options = [{ value: 'logout', title: 'Kirjaudu ulos' }];

const UserMenu = ({ user, onLogout }) => (
  <div>
    <UserButton
      color="inherit"
      label={user.name}
      icon={ArrowDropDownIcon}
      options={options}
      onSelect={() => onLogout()}
    />
    {/* <UserIconButton
      color='inherit'
      icon={PersonIcon}
      options={options}
      onSelect={() => onLogout()}
    /> */}
  </div>
);

const UserButton = styled(MenuButton)`
  @media (max-width: 640px) {
    display: none !important;
  }
`;

// const UserIconButton = styled(MenuButton)`
//   @media (min-width: 641px) {
//     display: none !important;
//   }
// `;

export const mapStateToProps = (state) => {
  return {
    user: state.common.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onLogout: userActions.logout,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
