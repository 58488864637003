import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { readItem } from '../common/item.api';
import Article from './article.component';
import ErrorBoundary from '~infra/errorBoundary.component.js';

const styles = (theme) => ({
  rightIcon: {
    marginLeft: theme.spacing(1),
    height: 16,
    width: 16,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

const TextViewPage = ({ match, history, classes }) => {
  const [item, setItem] = useState(null);
  const { database, itemId } = match.params;

  // This is the newer text view component, use this in the future
  // then remove unnecessary data from redux
  useEffect(() => {
    async function read() {
      // item not needed elsewhere -> just fetch it directly
      // TODO remove criteria and redux stuff
      const readedItem = await readItem({
        criteria: { database },
        itemId,
      });
      setItem(readedItem);
    }
    read();
  }, [itemId]);

  const onGoBack = () => {
    history.goBack();
  };

  return (
    <ErrorBoundary>
      <Article item={item} onGoBack={onGoBack} classes={classes}>
        <Button variant={'contained'} color="primary" onClick={onGoBack}>
          <NavigateBeforeIcon className={classes.leftIcon} />
          Takaisin
        </Button>
      </Article>
    </ErrorBoundary>
  );
};

export default connect(null, null)(
  withStyles(styles)(TextViewPage),
  withRouter(TextViewPage)
);
