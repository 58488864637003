import axios from 'axios';
import he from 'he';
import queryString from 'query-string';

const requestConfig = {
  headers: { 'Content-Type': 'application/jsonrequest' },
  // large timeout value because of occasional slow search responses
  timeout: 2 * 60 * 1000,
};

const checkResponse = (response) => {
  if (response.status !== 200) {
    const err = {
      statusCode: response.status,
    };
    throw err;
  }

  // This step is necessary for checking logins with wrong credentials;
  // the API responds with 200 and an html-document that contains a login page.
  if (!response.data.response) {
    const err = {
      statusCode: 403,
    };
    throw err;
  }

  if (response.data.response.status.statusCode !== '0') {
    const err = {
      statusCode: parseInt(response.data.response.status.statusCode, 10),
    };
    throw err;
  }
};

const apiProxyUrl = document.getElementById('app').getAttribute('data-api-url');
let apiBaseUrl = apiProxyUrl;
// Hardcoded test urls
if (window.location.href.indexOf('-dev.taitodev.com') !== -1) {
  apiBaseUrl =
    'https://hsgreditest.materialbank.net:8443/MediaKsiInternalAjaxUI/';
}
if (window.location.href.indexOf('-test.taitodev.com') !== -1) {
  apiBaseUrl =
    'https://hsarkisto1.materialbank.net:8443/MediaKsiInternalAjaxUI/';
}

export const getBaseUrl = (endpoint) => {
  const sessionId = localStorage.getItem('sessionId');
  const sessionParam =
    sessionId && sessionId !== 'null' ? `;jsessionid=${sessionId}` : '';
  return `${apiBaseUrl}${endpoint}${sessionParam}`;
};

export const post = async (service, req) => {
  console.log(`api call: ${JSON.stringify(req)}`);
  const baseUrl = getBaseUrl('xhp');
  const response = await axios.post(
    `${baseUrl}?id=MediaKsi${service}`,
    queryString.stringify({ JSONRequest: JSON.stringify(req) }),
    requestConfig
  );
  checkResponse(response);
  return response;
};

export const parseValue = (value) => {
  return he.decode(queryString.parse(`value=${value}`).value);
};

export const getSivuarkistoUrl = () => {
  let sivuarkistoUrl = document
    .getElementById('app')
    .getAttribute('data-sivuarkisto-url');
  // Hardcoded test urls
  if (window.location.href.indexOf('-dev.taitodev.com') !== -1) {
    sivuarkistoUrl = 'https://gredi-sivuarkisto-dev.taitodev.com';
  }
  if (window.location.href.indexOf('-test.taitodev.com') !== -1) {
    sivuarkistoUrl = 'https://gredi-sivuarkisto-test.taitodev.com';
  }
  return sivuarkistoUrl;
};
