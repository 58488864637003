import update from 'immutability-helper';
import { createActions, handleActions } from 'redux-actions';

// Default state
const defaultState = {
  user: null,
  databasesBySection: {
    initialized: false,
    pictures: [],
    texts: [],
  },
  drawerVisible: true,
};

// Actions
export const { common } = createActions({
  COMMON: {
    INIT_APP: () => ({}),
    INIT_USER: (user, databasesBySection) => ({
      user,
      databasesBySection,
    }),
    TOGGLE_DRAWER: (visible) => ({ visible }),
  },
});

// Reducer
export const commonReducer = handleActions(
  {
    [common.initApp]() {
      return defaultState;
    },
    [common.initUser](state, { payload: { user, databasesBySection } }) {
      return { ...state, user, databasesBySection };
    },
    [common.toggleDrawer](state, { payload: { visible } }) {
      const v = visible !== undefined ? visible : !state.drawerVisible;
      return update(state, {
        drawerVisible: { $set: v },
      });
    },
  },
  defaultState
);
