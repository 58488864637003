import { Component } from 'react';

class Listeners extends Component {
  componentDidMount() {
    window.addEventListener('dragstart', this.handleDragStart);
  }

  componentWillUnmount() {
    window.removeEventListener('dragstart', this.handleDragStart);
  }

  handleDragStart = (e) => {
    /* This has something to do with dragging items to Naviga,
    which stopped work on Chrome version 96. Issue CFR-330 */
    for (let i = e.dataTransfer.items.length - 1; i >= 0; i -= 1) {
      if (e.dataTransfer.items[i].kind === 'file') {
        e.dataTransfer.items.remove(i);
      }
    }
  };

  render() {
    return null;
  }
}

export default Listeners;
