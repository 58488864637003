import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import MenuBookIcon from '@material-ui/icons/MenuBook';

import ArticleInfo from './articleInfo.component';
import { asItemDownloadUrl, asSivuarkistoUrl } from '../common/item.api';
import { addSearchStateToPath } from '~search/search.util';
import Item from '~search/item.component';
import { appear } from '~misc/animations.util';

const propTypes = {
  resultNumber: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  criteria: PropTypes.object.isRequired,
  paging: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelectPrev: PropTypes.func,
  onSelectNext: PropTypes.func,
  onShow: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  // TODO pass these styles from searchDrawer to here
  rightIcon: {
    marginLeft: theme.spacing(1),
    height: 20,
    width: 20,
  },
  linkIcon: {
    marginLeft: theme.spacing(1),
    height: 16,
    width: 16,
    transform: 'translateY(1.5px)',
  },
});
const Text = ({
  resultNumber,
  item,
  criteria,
  paging,
  selected,
  onSelectPrev,
  onSelectNext,
  onShow,
  classes,
}) => {
  const textRef = useRef(null);

  const executeScroll = () =>
    textRef.current.scrollIntoView({
      behavior: 'instant',
      inline: 'center',
    });

  useEffect(() => {
    if (selected) {
      executeScroll();
    }
  }, [selected]);

  const textsLength = item.fields.texts.length;
  const textValues = [];
  for (let i = 0; i < textsLength; i += 1) {
    textValues.push(item.fields.texts[i].value);
  }

  return (
    <TextWrapper id={`result${resultNumber}`} ref={textRef}>
      <Item
        selected={selected}
        onSelectPrev={onSelectPrev}
        onSelectNext={onSelectNext}
        showNav={false}
        minHeight="1vh"
        padding="12px 20px"
      >
        <Flex>
          <Header>
            <Headline
              selected={selected}
              onClick={onShow}
              href={addSearchStateToPath(
                '/texts/browse',
                criteria,
                paging,
                item.id
              )}
            >
              <HeadlineTopic selected={selected}>
                {resultNumber}.&nbsp;&nbsp;
                {item.fields.OTS ? item.fields.OTS.join(' ') : 'Ei otsikkoa'}
              </HeadlineTopic>
              <br />
              <Information>
                <ArticleInfo item={item} />
              </Information>
            </Headline>
            {item.fields.OIK && item.fields.OIK.join() && (
              <Link
                to={`/text/${item.db}-${item.dbType}/${item.fields.OIK[0]}`}
              >
                <CorrectionButton variant={'contained'} color="accent">
                  OIKAISU
                  <LinkIcon className={classes.rightIcon} />
                </CorrectionButton>
              </Link>
            )}
            <Downloads>
              <a
                href={asSivuarkistoUrl(item)}
                target="_blank"
                rel="noopener noreferrer"
              >
                AVAA SIVUARKISTOSSA
                <MenuBookIcon className={classes.linkIcon} />
              </a>
              |
              <a
                href={asItemDownloadUrl(item, 'page', false)}
                target="_blank"
                rel="noopener noreferrer"
              >
                SIVU (PDF)
              </a>
              |
              <a
                href={asItemDownloadUrl(item, 'clip', false)}
                target="_blank"
                rel="noopener noreferrer"
              >
                LEIKE (PDF)
              </a>
            </Downloads>
          </Header>
          <InnerText>
            {(textValues.length && textValues.join(' ')) || item.fields.LOGO}
          </InnerText>
        </Flex>
      </Item>
    </TextWrapper>
  );
};

Text.propTypes = propTypes;

const TextWrapper = styled.div`
  max-height: 190px;
  min-height: 50px;
  height: auto;
  margin: 2px;
  padding: 2px;
  animation: ${appear} 0.8s forwards;

  :nth-child(odd) {
    background-color: #f0f0f0;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const Headline = styled.span`
  color: #1a9bf5;
  overflow: hidden;
  white-space: nowrap;
`;

const HeadlineTopic = styled.a`
  display: block;
  cursor: pointer;
  color: #1a9bf5;
  font-size: 16px;
  line-height: 1.2em;

  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: ${(props) => (props.selected ? 'underline' : 'none')};
  :hover {
    text-decoration: underline;
  }
`;

const Information = styled.div`
  color: #6a737d;
  font-size: 14px;
  line-height: 1.2em;
`;

const InnerText = styled.p`
  margin-top: 4px;
  margin-bottom: 0px;
  overflow: hidden;
  line-height: 1.45em;
  max-height: 4.5em;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* max lines to show */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  font-size: 14px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 0.3em;
`;

const CorrectionButton = styled(Button)`
  margin-left: 30px !important;
  height: 30px !important;
  padding: 4px 16px !important;
`;

const Downloads = styled.div`
  flex-shrink: 0;
  margin-left: auto;
  margin-top: 8px;
  a {
    margin: 0 15px;
    color: #1a9bf5;
  }
`;

export default withStyles(styles)(Text);
