import React from 'react';

const createString = (props) => {
  const infoStrings = [];
  const fields = props.item.fields;
  const infoFields = [
    fields.LEH,
    fields.OS,
    fields.PVM,
    fields.TEKI,
    fields.MRK,
    fields.PA,
  ];
  for (let i = 0; i < infoFields.length; i += 1) {
    if (infoFields[i] && infoFields[i][0] !== '') {
      let string;
      if (i === 4) {
        string = `${infoFields[i][0]} merkkiä`;
      } else if (i === 5) {
        string = `${infoFields[i][0]}. painos`;
      } else {
        string = `${infoFields[i][0]}`;
      }
      infoStrings.push(string);
    }
  }
  const InfoString = infoStrings.join(' – ');
  return InfoString;
};

const ArticleInfo = (props) => <span>{createString(props)}</span>;

export default ArticleInfo;
